import { Badge } from "@radix-ui/themes";
import { EntityRevisionStatus } from "@prisma-app/client";
import { never } from "~/lib/never";

export const statusToText = (status: EntityRevisionStatus) => {
  switch (status) {
    case "AFFIRMED":
      return "Affirmed";
    case "ESCALATED":
      return "Escalated";
    case "FLAGGED":
      return "Flagged";
    case "IN_REVIEW":
      return "In review";
    case "PENDING_REVIEW":
      return "Pending review";
    case "REVIEWED":
      return "Reviewed";
    default:
      return never(status);
  }
};

export const statusToColor = (status: EntityRevisionStatus) => {
  switch (status) {
    case "AFFIRMED":
      return "green";
    case "ESCALATED":
      return "red";
    case "FLAGGED":
      return "red";
    case "IN_REVIEW":
      return "orange";
    case "PENDING_REVIEW":
      return "yellow";
    case "REVIEWED":
      return "blue";
    default:
      return never(status);
  }
};

export function EntityRevisionStatusBadge({
  status,
}: {
  status: EntityRevisionStatus;
}) {
  return (
    <Badge variant="soft" color={statusToColor(status)}>
      {statusToText(status)}
    </Badge>
  );
}
